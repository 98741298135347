import { isLive } from "@/libs/clientConfig";
import { useEffect } from "react";

const WIDGET_CDN_URL = "https://d3kvdshyikpa31.cloudfront.net";
export const useDealNowWidget = () => {
  useEffect(() => {
    if (isLive()) {
      return;
    }
    async function initDealNowWidget() {
      const response = await fetch(`${WIDGET_CDN_URL}/build.json`);
      const build = await response.json();
      const link = document.createElement("link");
      link.id = "deal-now-widget-style";
      link.rel = "stylesheet";
      link.crossOrigin = "";
      link.href = `${WIDGET_CDN_URL}/pa-dealnow-widgets.styles.${build.version}.css`;
      document.head.appendChild(link);

      const script = document.createElement("script");
      script.id = "deal-now-widget-script";
      script.type = "module";
      script.crossOrigin = "";
      script.src = `${WIDGET_CDN_URL}/pa-dealnow-widgets.es.${build.version}.js`;
      document.body.appendChild(script);
      script.onload = () => {
        if (typeof (window as any).mountReactWidget === "function") {
          (window as any).mountReactWidget(
            "Authentication",
            "authentication-widget"
          );
        } else {
          console.error("mountReactWidget function not found");
        }
      };
    }
    // Check if the link element already exists
    if (
      document.getElementById("deal-now-widget-style") ||
      document.getElementById("deal-now-widget-script")
    ) {
      return;
    }

    initDealNowWidget();
    // Clean up function to remove appended elements when the component unmounts
    return () => {
      const link = document.getElementById("deal-now-widget-style");
      const script = document.getElementById("deal-now-widget-script");
      if (link) document.head.removeChild(link);
      if (script) document.body.removeChild(script);
    };
  }, []);
};
